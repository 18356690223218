import HttpCommon from './HttpCommon'

import { ErrorWrapper, ResponseWrapper } from './util'
export default class ActivityService {
    constructor() {
        this.http = new HttpCommon('activity', true)
    }

    getActivities = async (id) => {
        try {
            const response = await this.http.get(``)
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }

    getActivityInfo = async () => {
        try {
            const response = await this.http.get('/get_activity_info')
            return new ResponseWrapper(response).data
        } catch (error) {
            new ErrorWrapper(error)
        }
    }
}
