import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import clsx from "clsx";
import SpotLights from "./SpotLights";
import OpalList from "./OpalList";

const InvestTradePageContent = ({ activities }) => {
  const [selectedVenue, setSelectedVenue] = useState("All Venues");
  return (
    <Row className="justify-content-center mx-auto mw-1440 investtrade_content">
      <OpalList
        selectedVenue={selectedVenue}
        setSelectedVenue={setSelectedVenue}
        activities={activities}
      />
      {(selectedVenue === "All Venues" || selectedVenue === "Marketplace") && (
        <>
          <Col
            md={12}
            className={clsx("marketplace", {
              "mt-0": selectedVenue === "Marketplace",
            })}
          >
            <b>Marketplace</b>
          </Col>
          <Col md={12} className="marketplace-desc">
            The secondary marketplace for Opals will open in 2025
            <hr />
          </Col>
        </>
      )}
      <SpotLights />
    </Row>
  );
};

export default InvestTradePageContent;
