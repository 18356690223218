import React from "react";
import { Col, Tabs, Tab, Row, Dropdown } from "react-bootstrap";
import "../../../styles/components/InvestTrade/Category/style.scss";

const Category = ({
  data,
  children,
  selectedCategory,
  setSelectedCategory,
  selectedVenue,
  setSelectedVenue,
}) => {
  const categoryHandler = (category) => {
    setSelectedCategory(category);
  };
  const venueHandler = (venue) => {
    setSelectedVenue(venue);
  };
  return (
    <>
      <Row className="trade-category">
        <Col>
          <Dropdown className="d-block d-md-none">
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
              {selectedCategory}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {data.map((category) => {
                return (
                  <Dropdown.Item onClick={() => categoryHandler(category)}>
                    {category}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown className="d-block d-md-none">
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
              {selectedVenue}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => venueHandler("All Venues")}>
                {"All Venues"}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => venueHandler("Initial Offerings")}>
                {"Initial Offerings"}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => venueHandler("Marketplace")}>
                {"Marketplace"}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <div className="tabs">
            <Tabs
              id="trade_tab_category"
              activeKey={selectedCategory}
              onSelect={(k) => categoryHandler(k)}
              className="d-none d-md-flex"
            >
              {data.map((category) => {
                return <Tab key={category} eventKey={category} title={category}></Tab>;
              })}
            </Tabs>

            <Tabs
              id="venues_tab_category"
              activeKey={selectedVenue}
              onSelect={(k) => venueHandler(k)}
              className="d-none d-md-flex justify-content-end"
            >
              <Tab eventKey={"All Venues"} title={"All Venues"}></Tab>
              <Tab
                eventKey={"Initial Offerings"}
                title={"Initial Offerings"}
              ></Tab>
              <Tab eventKey={"Marketplace"} title={"Marketplace"}></Tab>
            </Tabs>
          </div>

          {children}
        </Col>
      </Row>
    </>
  );
};

export default Category;
