import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import useDebounce from '../../hooks/useDebounce';
import { navigate } from "gatsby";
import Layout from "../../components/Layout";
import Banner from "../../components/InvestTrade/Banner";
import PageContent from "../../components/InvestTrade/PageContent";
import { client as prismicClient } from "../../libs/prismic";
import Loader from "../../components/Loader";
import { actionTypes } from "../../store/App/type";
import { redirectPageByAccounts } from "../../utils/pageAccess";
import {
  actionTypes as dialogActionTypes,
} from "../../store/Dialog/type";
import { actionTypes as activityActionTypes } from "../../store/Activities/type";
import Hero from "../../components/Hero";
import ActivityService from "../../services/ActivityService";
import "../../styles/components/InvestTrade/PageContent/style.scss";
import "../../styles/pages/investtrade.scss";

const activityService = new ActivityService();

const InvestTrade = ({ location }) => {
  const [investTradeDoc, setInvestTradeDoc] = useState();
  const [activities, setActivities] = useState([]);
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search);
  const from = params.get("from");
  const addmethod = params.get("addmethod");

  const authUser = useSelector(
    (state) => state?.app?.authUser
  );

  const loading = useSelector(
    (state) => state?.app?.loading
  );

  const parentAccounts = useSelector(
    (state) => state?.accounts?.parentAccounts
  );

  const refreshKey = useSelector(
    (state) => state?.dialog?.refreshKey
  );

  const disqualificationOfAccredited = useSelector(
    (state) => state?.investor_qualification?.disqualificationOfAccredited
  );

  useEffect(() => {
    if (from !== 'home') {
      dispatch({ type: actionTypes.LOAD_ACCOUNTS_DATA, value: null })
    }
  }, [from, dispatch]);

  useEffect(() => {
    if (refreshKey !== '') {
      dispatch({ type: actionTypes.LOAD_ACCOUNTS_DATA, value: null })
    }
  }, [refreshKey, dispatch]);

  const fetchActivityData = useCallback(async () => {
    const response = await activityService.getActivityInfo();
    dispatch({ type: activityActionTypes.SET_ACTIVITIES, value: response });
    setActivities(response);
  }, [dispatch]);

  // Define debouncedFetchData with a 1-minute delay
  const debouncedFetchData = useDebounce(fetchActivityData, 60000);

  useEffect(() => {
    if (authUser?.email) {
      fetchActivityData();
    }
  }, [authUser, fetchActivityData]);

  // Call debouncedFetchData every 1 minute
  useEffect(() => {
    const intervalId = setInterval(() => {
      debouncedFetchData();
    }, 60000);

    return () => clearInterval(intervalId);
  }, [debouncedFetchData]);

  useEffect(() => {
    if (parentAccounts && Array.isArray(parentAccounts)) {
      const _accounts = parentAccounts?.filter(el => el.hasOwnProperty('zipcode'));
      if (addmethod === '1' && _accounts.length > 0) {
        dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_add_payment_account', show: true, payment: null, account: _accounts[_accounts.length - 1] } });
        navigate('/invest-trade/')
      }
    }
  }, [addmethod, parentAccounts, dispatch]);

  useEffect(() => {
    if (parentAccounts && Array.isArray(parentAccounts)) {
      redirectPageByAccounts(parentAccounts)
    }
  }, [parentAccounts]);

  useEffect(() => {
    async function fetchData() {
      const response = await prismicClient().getSingle("invest_and_trade");
      setInvestTradeDoc(response);
    }
    if (authUser?.email) {
      fetchData();
    }
  }, [authUser]);

  // useEffect(() => {
  //   if (disqualificationOfAccredited && parentAccounts.length > 1) {
  //     navigate('/accounts');
  //   }
  // }, [disqualificationOfAccredited, parentAccounts]);

  return investTradeDoc ? (
    <Layout>
      <Hero />
      <div style={{ minHeight: "calc(100vh - 400px)" }}>
        <Banner content={investTradeDoc} />
        {loading && parentAccounts ? <Loader /> : <PageContent activities={activities} />}
      </div>
    </Layout>
  ) : (
    <Loader />
  );
};

export default InvestTrade;
