import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import * as prismic from "@prismicio/client";
import { RichText } from "prismic-reactjs";
import { shuffle } from "../../../utils";
import { client as prismicClient } from "../../../libs/prismic";

const SpotLights = ({ selectedVenue, setSelectedVenue }) => {
  const [advisorSpotLightDoc, setAdvisorSpotLightDoc] = useState();
  const [twoSpotLights, setTwoSpotLights] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await prismicClient().getSingle("advisor_spotlight");
      setAdvisorSpotLightDoc(response);

      const spotlightsResponse = await prismicClient().get({
        predicates: prismic.predicates.at("document.type", "spotlights"),
        orderings: {
          field: "document.first_publication_date",
          direction: "desc",
        },
      });
      let _spotlightsIndex = [];
      spotlightsResponse?.results?.forEach((e, index) => {
        _spotlightsIndex.push(index);
      });
      _spotlightsIndex = shuffle(_spotlightsIndex);
      _spotlightsIndex = _spotlightsIndex.slice(0, 2);
      let _spotlights = [];
      _spotlightsIndex.forEach((el) => {
        _spotlights.push(spotlightsResponse.results[el]);
      });

      setTwoSpotLights(_spotlights);
    }
    fetchData();
  }, []);

  return (
    <div className="three-column">
      {twoSpotLights.map((spotlight, index) => {
        return (
          <div className="card" key={index}>
            <p className="title">
              <b>SPOTLIGHT on</b>
            </p>
            <div className="name-fields">
              <img src={spotlight.data?.image?.url} alt="" />
              <p>
                <b>{spotlight.data?.image_title}</b>
              </p>
            </div>
            <RichText render={spotlight.data?.content} />
            <Button className="learn-more">LEARN MORE</Button>
          </div>
        );
      })}

      <div className="advisor card">
        <div className="heading">
          <img alt={advisorSpotLightDoc?.data['advisor-image']?.alt} src={advisorSpotLightDoc?.data['advisor-image']?.url}/>
          <div>
            <RichText
              render={advisorSpotLightDoc?.data?.heading}
            />
          </div>
        </div>
        <RichText
          render={advisorSpotLightDoc?.data?.content}
        />
      </div>
    </div>
  );
};
export default SpotLights;
