import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { Col } from "react-bootstrap";
import * as prismic from "@prismicio/client";
import { client as prismicClient } from "../../../libs/prismic";
import { Table, Thead, Tbody, Tr, Th } from "../../../components/Table";
import InvestTradeCatetory from "../Category";
import { showAlert, NOT_VERIFIED_OPAL_BUY } from "../../../store/reducers/alert";
import { actionTypes } from "../../../store/Dialog/type";
import { navigate } from "gatsby";
import TableRow from "./TableRow";
import { isAfter5days, sortOpals } from "../../../utils/validationFunc";

export const OPAL_TYPE = {
  ALL: "All Opal Types",
  PE: "Private Equity",
  HF: "Hedge",
  VC: "Venture Capital",
  RE: "Real Estate",
};

const OpalList = ({
  selectedVenue,
  setSelectedVenue,
  activities,
  showAlert,
}) => {
  const dispatch = useDispatch();
  const accounts = useSelector(
    (state) => state?.accounts?.parentAccounts
  );
  const [categories, setCategries] = useState([OPAL_TYPE.ALL]);
  const [selectedCategory, setSelectedCategory] = useState(OPAL_TYPE.ALL);
  const [opals, setOpals] = useState([]);
  const [brandDoc, setBrandDoc] = useState();

  const type = useMemo(() => {
    const type = {
      qualified_purchaser: false,
      accredited_investor: false,
    };
    accounts.filter((el) => el.hasOwnProperty('zipcode')).forEach((account) => {
      type[account.qualification] = true;
    });
    return type;
  }, [accounts]);

  const buyHandler = useCallback((opal, brand) => {
    if (accounts.length === 0 || (accounts.length > 0 && !accounts[0].hasOwnProperty('approvalTemplate'))) {
      const alertType = { type: NOT_VERIFIED_OPAL_BUY };
      showAlert({ ...alertType, subType: 'non-ria' });
      return;
    } else {
      if (accounts.length > 0 && accounts[0].type === 'ria') {
        if (accounts.slice(1).filter((el) => el.hasOwnProperty('approvalTemplate')).length === 0) {
          const alertType = { type: NOT_VERIFIED_OPAL_BUY };
          showAlert({ ...alertType, subType: 'ria' });
          return;
        }
      } else if (accounts.length === 1 && accounts[0].type !== 'ria') {
        dispatch({ type: actionTypes.SET_OPAL, value: { ...opal, brand } });
        dispatch({ type: actionTypes.SET_ACCOUNT, value: accounts[0] });
        dispatch({ type: actionTypes.SHOW_DIALOG, value: { type: 'dialog_payment', show: true, fromBack: false, payment: null, cost: '0', account: accounts.filter(el => el.hasOwnProperty('approvalTemplate'))[0] } });
        return;
      } else {
        if (accounts.filter((el) => el.hasOwnProperty('approvalTemplate')).length === 1 &&
          ((accounts.filter((el) => el.hasOwnProperty('approvalTemplate'))[0].qualification === 'accredited_investor' && opal.availability !== 'QP Only') ||
            (accounts.filter((el) => el.hasOwnProperty('approvalTemplate'))[0].qualification === 'qualified_purchaser' && opal.availability !== 'Accredited Only'))) {
          dispatch({ type: actionTypes.SET_OPAL, value: { ...opal, brand } });
          dispatch({ type: actionTypes.SET_ACCOUNT, value: accounts[0] });
          dispatch({ type: actionTypes.SHOW_DIALOG, value: { type: 'dialog_payment', show: true, fromBack: false, payment: null, cost: '0', account: accounts.filter(el => el.hasOwnProperty('approvalTemplate'))[0] } });
          return;
        }
      }
      dispatch({ type: actionTypes.SET_OPAL, value: { ...opal, brand } });
      dispatch({ type: actionTypes.SHOW_DIALOG, value: { type: 'dialog_select_accounts', show: true, payment: null } });
    }
  }, [accounts, showAlert, dispatch]);

  const shareOpalHandler = useCallback((opal, brand) => {
    if (accounts.length === 0 || (accounts.length > 0 && !accounts[0].hasOwnProperty('approvalTemplate'))) {
      const alertType = { type: NOT_VERIFIED_OPAL_BUY };
      showAlert({ ...alertType, subType: 'non-ria' });
      return;
    } else {
      if (accounts.length > 0 && accounts[0].type === 'ria') {
        if (accounts.slice(1).filter((el) => el.hasOwnProperty('approvalTemplate')).length === 0) {
          const alertType = { type: NOT_VERIFIED_OPAL_BUY };
          showAlert({ ...alertType, subType: 'ria' });
          return;
        }
      }
      dispatch({ type: actionTypes.SET_OPAL, value: { ...opal, brand } });
      dispatch({ type: actionTypes.SHOW_DIALOG, value: { type: 'dialog_share_opal', show: true, payment: null } });
    }
  }, [accounts, showAlert, dispatch]);

  const detailHandler = useCallback((brandUid, opal_name) => {
    navigate(`/invest-trade/${brandUid}`);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const brandResponse = await prismicClient().get({
        predicates: prismic.predicates.at(
          "document.type",
          "opal_fund_partners"
        ),
        orderings: {
          field: "document.first_publication_date",
          direction: "desc",
        },
      });
      setBrandDoc(brandResponse);
    }
    fetchData();
  }, []);

  useEffect(async () => {
    if (brandDoc && accounts) {
      let _categories = [OPAL_TYPE.ALL];
      let _opals = [];

      brandDoc?.results?.forEach((brand) => {
        brand.data?.opals.forEach((opal) => {
          if (isAfter5days(opal) || !opal.display) return;

          if (opal.availability === 'Accredited and QP') {
            _opals.push({ brand, opal });
            if (
              !isEmpty(opal.fund_type) &&
              !_categories.includes(OPAL_TYPE[opal.fund_type])
            ) {
              _categories = [..._categories, OPAL_TYPE[opal.fund_type]];
            }
          } else {
            if (opal.availability === 'Accredited Only') {
              if (type.accredited_investor === true) {
                _opals.push({ brand, opal });
                if (
                  !isEmpty(opal.fund_type) &&
                  !_categories.includes(OPAL_TYPE[opal.fund_type])
                ) {
                  _categories = [..._categories, OPAL_TYPE[opal.fund_type]];
                }
              }
            } else if (opal.availability === 'QP Only') {
              if (type.qualified_purchaser === true) {
                _opals.push({ brand, opal });
                if (
                  !isEmpty(opal.fund_type) &&
                  !_categories.includes(OPAL_TYPE[opal.fund_type])
                ) {
                  _categories = [..._categories, OPAL_TYPE[opal.fund_type]];
                }
              }
            }
          }
        });
      });

      _opals.forEach(({ opal }) => {
        const activity = activities.find(activity => opal.opal_name === activity._id);
        if (activity) {
          opal.capPercent = Math.round(activity.total / opal.maximum_target_raise * 100);
        }
      });

      _opals = sortOpals(_opals);
      setCategries(_categories.sort());
      setOpals(_opals);
    }
  }, [brandDoc, accounts, type]);

  const filteredOpals = useMemo(() => {
    return opals?.filter(
      (item) =>
        selectedCategory === OPAL_TYPE.ALL ||
        OPAL_TYPE[item?.opal.fund_type] === selectedCategory
    );
  }, [opals, selectedCategory]);

  return (
    <InvestTradeCatetory
      data={categories}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
      selectedVenue={selectedVenue}
      setSelectedVenue={setSelectedVenue}
    >
      {(selectedVenue === "All Venues" ||
        selectedVenue === "Initial Offerings") && (
          <>
            <Col md={12} className="d-none d-md-block">
              <Table>
                <Thead>
                  <Tr>
                    <Th style={{ width: '27%' }}>Initial Opal Offerings</Th>
                    <Th style={{ minWidth: '154px' }}>Fund Manager</Th>
                    <Th style={{ width: '27%' }}>Strategy</Th>
                    <Th style={{ width: '15%' }} className="text-right">Minimum </Th>
                    <Th style={{ minWidth: '124px' }} className="text-right">Availability</Th>
                    <Th style={{ width: '1.5%' }}></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredOpals?.map((item, index) => {
                    return (
                      <TableRow
                        key={index}
                        brand={item.brand}
                        opal={item.opal}
                        isParent={true}
                        detailHandler={detailHandler}
                        buyHandler={buyHandler}
                        shareOpalHandler={shareOpalHandler}
                        title="See more details"
                      />
                    );
                  })}
                </Tbody>
              </Table>
            </Col>

            <Col md={12} className="initial-offerings d-md-none">
              <b>Initial Opal Offerings</b>
            </Col>
            <Col md={12} className="d-md-none">
              <Table>
                <Tbody>
                  {filteredOpals?.map((item, index) => {
                    return (
                      <TableRow
                        key={index}
                        brand={item.brand}
                        opal={item.opal}
                        isParent={true}
                        detailHandler={detailHandler}
                        buyHandler={buyHandler}
                        shareOpalHandler={shareOpalHandler}
                        title="See more details"
                      />
                    );
                  })}
                </Tbody>
              </Table>
            </Col>
          </>
        )}
    </InvestTradeCatetory>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    showAlert: (...rest) => {
      dispatch(showAlert(...rest))
    },
  }
}

export default connect(undefined, mapDispatchToProps)(React.memo(OpalList));
